<template>
  <div class="layout d-flex">
    <div class="col-12  text-center mb-5">
      <img
        class="p-5"
        src="@/assets/logo.svg"
        alt="logo"
      >
    </div>
    <div class="col-12 mt-5">
      <router-view class="auth-content" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>
